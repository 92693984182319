import logo from './assets/images/logo.jpeg';
import './App.css';
import Navbar from './components/Navbar';
import Footer from './components/Navbar/footer';
import { BrowserRouter as Router, Routes, Route}
	from 'react-router-dom';
import Home from './pages/home';
import About from './pages/about';
import Architecture from './pages/architecture';
import Connection from './pages/connectionDesign';
import SignUp from './pages/automation';
import Contact from './pages/contact';
import Solutions from './pages/solutions';
import YBO from './pages/ybo';
import Interior from './pages/Interior';

import BIM from './pages/bim';
// import function to register Swiper custom elements
// register Swiper custom elements

function App() {
  return (
    <div>
    <Router>
    <Navbar />
    <Routes>
      <Route exact path='/home'  element={<Home />} />
      <Route path='/about' element={<About/>} />
      <Route path='/contact' element={<Contact/>} />
      <Route path='/architecture' element={<Architecture/>} />
      <Route path='/automation' element={<SignUp/>} />
      <Route path='/solutions' element={<Solutions/>} />
    <Route path='/bim' element={<BIM/>} />
    <Route path='/ybo' element={<YBO/>} />
    <Route path='/Interior' element={<Interior/>} />
  
    <Route path='/connection' element={<Connection/>} />
    </Routes>
    <Footer />
    </Router>
   </div>
  );
}

export default App;
