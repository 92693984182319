import React from 'react';
import model from './room.obj';
import {OBJModel} from 'react-3d-viewer';
import { Helmet } from "react-helmet";
function About() {

  return (
    <div>
     
      <div>
        <span class="absolute top-0 left-0 z-[-1]">
          <svg width="495" height="470" viewBox="0 0 495 470" fill="none" xmlns="http://www.w3.org/2000/svg">
            <circle cx="55" cy="442" r="138" stroke="white" stroke-opacity="0.04" stroke-width="50"></circle>
            <circle cx="446" r="39" stroke="white" stroke-opacity="0.04" stroke-width="20"></circle>
            <path d="M245.406 137.609L233.985 94.9852L276.609 106.406L245.406 137.609Z" stroke="white" stroke-opacity="0.08" stroke-width="12"></path>
          </svg>
        </span>
        <span class="absolute top-0 right-0 z-[-1]">
          <svg width="493" height="470" viewBox="0 0 493 470" fill="none" xmlns="http://www.w3.org/2000/svg">
            <circle cx="462" cy="5" r="138" stroke="white" stroke-opacity="0.04" stroke-width="50"></circle>
            <circle cx="49" cy="470" r="39" stroke="white" stroke-opacity="0.04" stroke-width="20"></circle>
            <path d="M222.393 226.701L272.808 213.192L259.299 263.607L222.393 226.701Z" stroke="white" stroke-opacity="0.06" stroke-width="13"></path>
          </svg>
        </span>
      </div>
    
    <br></br>
       <div className="bg-white pt-[10px]">
 
</div>

<div className="max-w-[85rem] mx-auto px-4 sm:px-6 lg:px-8">

  <div className="grid md:grid-cols-2 gap-4 md:gap-8 xl:gap-20 md:items-center">
    <div>
      <h1 className="block text-3xl font-bold text-gray-800 sm:text-4xl lg:text-6xl lg:leading-tight ">Start your journey with <span className="text-transparent bg-clip-text bg-gradient-to-br from-yellow-300 to-red-500">SteelWits</span></h1>
      <p className="mt-3 text-lg text-gray-800 text-gray-400">We are Interior & Architectural, Structural Steel, Metal Building design and detailing service provider company with our corporate office and technical development centre in India. We are a bunch of committed and hard-working professionals & Engineers, who with their experience and expertise help customers harvest the greatest possible business value. More than the profits, professional ethics govern our functioning.</p>

      <div className="mt-7 grid gap-3 w-full sm:inline-flex">
        <a className="inline-flex justify-center items-center gap-x-3 text-center bg-blue-600 hover:bg-blue-700 border border-transparent text-sm lg:text-base text-white font-medium rounded-md focus:outline-none focus:ring-2 focus:ring-blue-600 focus:ring-offset-2 focus:ring-offset-white transition py-3 px-4 focus:ring-offset-gray-800" href="#">
          Get started
          <svg className="w-2.5 h-2.5" width="16" height="16" viewBox="0 0 16 16" fill="none">
            <path d="M5.27921 2L10.9257 7.64645C11.1209 7.84171 11.1209 8.15829 10.9257 8.35355L5.27921 14" stroke="currentColor" strokeWidth="2" strokeLinecap="round"/>
          </svg>
        </a>
        <a className="inline-flex justify-center items-center gap-x-3.5 text-sm lg:text-base text-center border hover:border-gray-300 shadow-sm font-medium rounded-md focus:outline-none focus:ring-2 focus:ring-gray-400 focus:ring-offset-2 focus:ring-offset-white transition py-3 px-4 border-gray-800 hover:border-gray-600 shadow-slate-700/[.7] focus:ring-gray-700 focus:ring-offset-gray-800" href="#">
          Contact sales team
        </a>
      </div>
     

    
    </div>


    <div className="relative ml-4">
      <img className="w-full rounded-md" src="https://images.unsplash.com/photo-1665686377065-08ba896d16fd?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=700&h=800&q=80" alt="Image Description" />
      <div className="absolute inset-0 -z-[1] bg-gradient-to-tr from-gray-200 via-white/0 to-white/0 w-full h-full rounded-md mt-4 -mb-4 mr-4 -ml-4 lg:mt-6 lg:-mb-6 lg:mr-6 lg:-ml-6 from-slate-800 via-slate-900/0 to-slate-900/0"></div>


      <div className="absolute bottom-0 left-0">
        <svg className="w-2/3 ml-auto h-auto text-white text-slate-900" width="630" height="451" viewBox="0 0 630 451" fill="none" xmlns="http://www.w3.org/2000/svg">
          <rect x="531" y="352" width="99" height="99" fill="currentColor"/>
          <rect x="140" y="352" width="106" height="99" fill="currentColor"/>
          <rect x="482" y="402" width="64" height="49" fill="currentColor"/>
          <rect x="433" y="402" width="63" height="49" fill="currentColor"/>
          <rect x="384" y="352" width="49" height="50" fill="currentColor"/>
          <rect x="531" y="328" width="50" height="50" fill="currentColor"/>
          <rect x="99" y="303" width="49" height="58" fill="currentColor"/>
          <rect x="99" y="352" width="49" height="50" fill="currentColor"/>
          <rect x="99" y="392" width="49" height="59" fill="currentColor"/>
          <rect x="44" y="402" width="66" height="49" fill="currentColor"/>
          <rect x="234" y="402" width="62" height="49" fill="currentColor"/>
          <rect x="334" y="303" width="50" height="49" fill="currentColor"/>
          <rect x="581" width="49" height="49" fill="currentColor"/>
          <rect x="581" width="49" height="64" fill="currentColor"/>
          <rect x="482" y="123" width="49" height="49" fill="currentColor"/>
          <rect x="507" y="124" width="49" height="24" fill="currentColor"/>
          <rect x="531" y="49" width="99" height="99" fill="currentColor"/>
        </svg>
      </div>
    
    </div>
   
  </div>
 
</div>

<div className="bg-white py-16 sm:py-16">
  <div className="mx-auto max-w-7xl px-6 lg:px-8">
    <h2 className="text-center text-lg font-semibold leading-8 text-gray-900">Connect & Collabrate Us by the Social</h2>
    <div className="mx-auto mt-1 grid max-w-lg grid-cols-4 items-center gap-x-8 gap-y-10 sm:max-w-xl sm:grid-cols-6 sm:gap-x-10 lg:mx-0 lg:max-w-none lg:grid-cols-5">
      <img className="col-span-2  max-h-25 w-full object-contain lg:col-span-1" src={require("./linkedin.svg").default}  alt="Transistor" width="158" height="48" />
      <img className="col-span-2 max-h-20  w-full object-contain lg:col-span-1" src={require("./teams.svg").default} alt="Reform" width="158" height="48" />
      <img className="col-span-2 max-h-20 w-full object-contain lg:col-span-1" src={require("./goto.png")} alt="Tuple" width="158" height="48" />
      <img className="col-span-2 max-h-20 w-full object-contain sm:col-start-2 lg:col-span-1" src={require("./Zoom.svg").default}  alt="SavvyCal" width="158" height="48" />
      <img className="col-span-2 max-h-20 w-full object-contain sm:col-start-auto lg:col-span-1"  src={require("./outlook.png")} alt="Statamic" width="158" height="48" />
    </div>
  </div>
  </div>
<div class="max-w-[85rem] px-4 py-10 sm:px-6 lg:px-8 lg:py-14 mx-auto">

  <div class="grid md:grid-cols-2 gap-12">
    <div >
  <div>
    <div class="max-w-2xl mx-auto">
      <div class="grid gap-12">
        <div>
          <h2 class="text-3xl text-gray-800 font-bold lg:text-xl ">
            Our <span class="text-transparent bg-clip-text bg-gradient-to-br from-yellow-300 to-red-500">Mission</span>
          </h2>
        </div>
        <p>
            Delivering high quality with affordable, value-driven and innovative services that satisfy our customers along with their customer expectations with Transparency.</p>
        
       {/* <div class="space-y-6 lg:space-y-10">
          <div class="flex">
            <svg class="flex-shrink-0 mt-2 h-6 w-6 text-gray-800 " xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" viewBox="0 0 16 16">
              <path fill-rule="evenodd" d="M14.763.075A.5.5 0 0 1 15 .5v15a.5.5 0 0 1-.5.5h-3a.5.5 0 0 1-.5-.5V14h-1v1.5a.5.5 0 0 1-.5.5h-9a.5.5 0 0 1-.5-.5V10a.5.5 0 0 1 .342-.474L6 7.64V4.5a.5.5 0 0 1 .276-.447l8-4a.5.5 0 0 1 .487.022zM6 8.694 1 10.36V15h5V8.694zM7 15h2v-1.5a.5.5 0 0 1 .5-.5h2a.5.5 0 0 1 .5.5V15h2V1.309l-7 3.5V15z"/>
              <path d="M2 11h1v1H2v-1zm2 0h1v1H4v-1zm-2 2h1v1H2v-1zm2 0h1v1H4v-1zm4-4h1v1H8V9zm2 0h1v1h-1V9zm-2 2h1v1H8v-1zm2 0h1v1h-1v-1zm2-2h1v1h-1V9zm0 2h1v1h-1v-1zM8 7h1v1H8V7zm2 0h1v1h-1V7zm2 0h1v1h-1V7zM8 5h1v1H8V5zm2 0h1v1h-1V5zm2 0h1v1h-1V5zm0-2h1v1h-1V3z"/>
            </svg>
            <div class="ml-5 sm:ml-8">
              <h3 class="text-base sm:text-lg font-semibold text-gray-800 ">
                High quality services
              </h3>
             
            </div>
          </div>
          <div class="flex">
            <svg class="flex-shrink-0 mt-2 h-6 w-6 text-gray-800 " xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" viewBox="0 0 16 16">
              <path d="M15 14s1 0 1-1-1-4-5-4-5 3-5 4 1 1 1 1h8zm-7.978-1A.261.261 0 0 1 7 12.996c.001-.264.167-1.03.76-1.72C8.312 10.629 9.282 10 11 10c1.717 0 2.687.63 3.24 1.276.593.69.758 1.457.76 1.72l-.008.002a.274.274 0 0 1-.014.002H7.022zM11 7a2 2 0 1 0 0-4 2 2 0 0 0 0 4zm3-2a3 3 0 1 1-6 0 3 3 0 0 1 6 0zM6.936 9.28a5.88 5.88 0 0 0-1.23-.247A7.35 7.35 0 0 0 5 9c-4 0-5 3-5 4 0 .667.333 1 1 1h4.216A2.238 2.238 0 0 1 5 13c0-1.01.377-2.042 1.09-2.904.243-.294.526-.569.846-.816zM4.92 10A5.493 5.493 0 0 0 4 13H1c0-.26.164-1.03.76-1.724.545-.636 1.492-1.256 3.16-1.275zM1.5 5.5a3 3 0 1 1 6 0 3 3 0 0 1-6 0zm3-2a2 2 0 1 0 0 4 2 2 0 0 0 0-4z"/>
            </svg>
            <div class="ml-5 sm:ml-8">
              <h3 class="text-base sm:text-lg font-semibold text-gray-800 ">
                Fostering affordable services.
              </h3>
             
            </div>
          </div>
          <div class="flex">
            <svg class="flex-shrink-0 mt-2 h-6 w-6 text-gray-800 " xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" viewBox="0 0 16 16">
              <path d="M8.864.046C7.908-.193 7.02.53 6.956 1.466c-.072 1.051-.23 2.016-.428 2.59-.125.36-.479 1.013-1.04 1.639-.557.623-1.282 1.178-2.131 1.41C2.685 7.288 2 7.87 2 8.72v4.001c0 .845.682 1.464 1.448 1.545 1.07.114 1.564.415 2.068.723l.048.03c.272.165.578.348.97.484.397.136.861.217 1.466.217h3.5c.937 0 1.599-.477 1.934-1.064a1.86 1.86 0 0 0 .254-.912c0-.152-.023-.312-.077-.464.201-.263.38-.578.488-.901.11-.33.172-.762.004-1.149.069-.13.12-.269.159-.403.077-.27.113-.568.113-.857 0-.288-.036-.585-.113-.856a2.144 2.144 0 0 0-.138-.362 1.9 1.9 0 0 0 .234-1.734c-.206-.592-.682-1.1-1.2-1.272-.847-.282-1.803-.276-2.516-.211a9.84 9.84 0 0 0-.443.05 9.365 9.365 0 0 0-.062-4.509A1.38 1.38 0 0 0 9.125.111L8.864.046zM11.5 14.721H8c-.51 0-.863-.069-1.14-.164-.281-.097-.506-.228-.776-.393l-.04-.024c-.555-.339-1.198-.731-2.49-.868-.333-.036-.554-.29-.554-.55V8.72c0-.254.226-.543.62-.65 1.095-.3 1.977-.996 2.614-1.708.635-.71 1.064-1.475 1.238-1.978.243-.7.407-1.768.482-2.85.025-.362.36-.594.667-.518l.262.066c.16.04.258.143.288.255a8.34 8.34 0 0 1-.145 4.725.5.5 0 0 0 .595.644l.003-.001.014-.003.058-.014a8.908 8.908 0 0 1 1.036-.157c.663-.06 1.457-.054 2.11.164.175.058.45.3.57.65.107.308.087.67-.266 1.022l-.353.353.353.354c.043.043.105.141.154.315.048.167.075.37.075.581 0 .212-.027.414-.075.582-.05.174-.111.272-.154.315l-.353.353.353.354c.047.047.109.177.005.488a2.224 2.224 0 0 1-.505.805l-.353.353.353.354c.006.005.041.05.041.17a.866.866 0 0 1-.121.416c-.165.288-.503.56-1.066.56z"/>
            </svg>
            <div class="ml-5 sm:ml-8">
              <h3 class="text-base sm:text-lg font-semibold text-gray-800 ">
                Simple and all-inclusive
              </h3>
              
            </div>
          </div>
  </div>*/}
      </div>
    </div>
  </div>
    </div>

    <div class="space-y-6 lg:space-y-10">
    <h2 class="text-3xl text-gray-800 font-bold lg:text-xl ">
            Our  <span class="text-transparent bg-clip-text bg-gradient-to-br from-yellow-300 to-red-500">Vision</span>
          </h2>
          <p>
          Belive and becoming the leading global player in providing engineering services and create a healthy and thriving ecosystem comprised of happy customers, employees and business partners.
          </p>
    
    
      
    </div>
   
  </div>
 
</div>


<div id="features">
  <div className="max-w-7xl mx-auto">
    <div className="md:w-3/3 lg:w-2/2">
     
      
      <h2 className="my-5 text-2xl font-bold text-gray-700  md:text-xl">
        A <span class="text-transparent bg-clip-text bg-gradient-to-br from-yellow-300 to-red-500">Technology</span>-first approach 
      </h2>
      <p className="text-gray-600 ">We specialize in providing Civil Engineering & Mechanical Engineering services and has a strong team of young energetic engineers lead by professionals with vast experience in Engineering, Project management, Software development and analytics.</p>
    </div><div className="mt-16 grid divide-x divide-y divide-gray-100 divide-gray-700 overflow-hidden  border border-gray-100 text-gray-600 border-gray-700 sm:grid-cols-2 lg:grid-cols-4 lg:divide-y-0 xl:grid-cols-4">
    <div className="group relative bg-gray-50 bg-blue-900 transition hover:z-[1] shadow-md hover:shadow-2xl hover:shadow-gray-600/10">
          <div className="relative space-y-8 py-12 p-8">
          <img src="https://cdn-icons-png.flaticon.com/512/4341/4341139.png" className="w-12" width="512" height="512" alt="burger illustration" />

          <div className="space-y-2">
            <h5 className="text-xl font-semibold text-gray-700 transition group-hover:text-secondary">
              Design & Quality
            </h5>
            <p className="text-gray-600 text-gray-300">
            Customers and employees. Both are wound in a common thread. Quality action taken by the employee is ultimately of benefit to the customer.
            </p>
          </div>
          <a href="#" className="flex items-center justify-between group-hover:text-secondary">
            <span className="text-sm">Read more</span>
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" className="w-5 h-5 -translate-x-4 text-2xl opacity-0 transition duration-300 group-hover:translate-x-0 group-hover:opacity-100">
              <path fillRule="evenodd" d="M12.97 3.97a.75.75 0 011.06 0l7.5 7.5a.75.75 0 010 1.06l-7.5 7.5a.75.75 0 11-1.06-1.06l6.22-6.22H3a.75.75 0 010-1.5h16.19l-6.22-6.22a.75.75 0 010-1.06z" clipRule="evenodd"></path>
            </svg>                
          </a>
        </div>
      </div>
      <div className="group relative bg-gray-50 bg-blue-900 transition hover:z-[1] hover:shadow-2xl hover:shadow-gray-600/10">
         <div className="relative space-y-8 py-12 p-8">
          <img src="https://cdn-icons-png.flaticon.com/512/4341/4341230.png" className="w-12" width="512" height="512" alt="burger illustration" />

          <div className="space-y-2">
            <h5 className="text-xl font-semibold text-gray-700 transition group-hover:text-secondary">
              Costing 
            </h5>
            <p className="text-gray-600 text-gray-300">
            we employ the most advanced technology to ensure speed, precision and cost-savings for clients..
            </p>
          </div>
          <a href="#" className="flex items-center justify-between group-hover:text-secondary">
            <span className="text-sm">Read more</span>
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" className="w-5 h-5 -translate-x-4 text-2xl opacity-0 transition duration-300 group-hover:translate-x-0 group-hover:opacity-100">
              <path fillRule="evenodd" d="M12.97 3.97a.75.75 0 011.06 0l7.5 7.5a.75.75 0 010 1.06l-7.5 7.5a.75.75 0 11-1.06-1.06l6.22-6.22H3a.75.75 0 010-1.5h16.19l-6.22-6.22a.75.75 0 010-1.06z" clipRule="evenodd"></path>
            </svg>                
          </a>
        </div>
      </div>
      <div className="group relative bg-gray-50 bg-blue-900 transition hover:z-[1] hover:shadow-2xl hover:shadow-gray-600/10">
        <div className="relative space-y-8 py-12 p-8">
          <img src="https://cdn-icons-png.flaticon.com/512/4331/4331030.png" className="w-12" width="512" height="512" alt="burger illustration" />

          <div className="space-y-2">
            <h5 className="text-xl font-semibold text-gray-700  transition group-hover:text-secondary">
              Secure
            </h5>
            <p className="text-gray-600 text-gray-300">
             projects led by commited and dedicated subject matter experts to transfering/exchanging the data in secure servers</p>
          </div>
          <a href="#" className="flex items-center justify-between group-hover:text-secondary">
            <span className="text-sm">Read more</span>
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" className="w-5 h-5 -translate-x-4 text-2xl opacity-0 transition duration-300 group-hover:translate-x-0 group-hover:opacity-100">
              <path fillRule="evenodd" d="M12.97 3.97a.75.75 0 011.06 0l7.5 7.5a.75.75 0 010 1.06l-7.5 7.5a.75.75 0 11-1.06-1.06l6.22-6.22H3a.75.75 0 010-1.5h16.19l-6.22-6.22a.75.75 0 010-1.06z" clipRule="evenodd"></path>
            </svg>                
          </a>
        </div>
      </div>
      <div className="group relative bg-gray-50 bg-blue-900 transition hover:z-[1] hover:shadow-2xl hover:shadow-gray-600/10">
        <div className="relative space-y-8 py-12 p-8 transition duration-300 group-hover:bg-white group-hover:bg-gray-800">
          <img src="https://cdn-icons-png.flaticon.com/512/4341/4341025.png" className="w-12" width="512" height="512" alt="burger illustration" />

          <div className="space-y-2">
            <h5 className="text-xl font-semibold text-gray-700 transition group-hover:text-secondary">
              Deliver On-time
            </h5>
            <p className="text-gray-600 text-gray-300">
             Scrum methodolgies used to implement designs to offer teams a framework to deliver work incrementally.
            </p>
          </div>
          <a href="#" className="flex items-center justify-between group-hover:text-secondary">
            <span className="text-sm">Read more</span>
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" className="w-5 h-5 -translate-x-4 text-2xl opacity-0 transition duration-300 group-hover:translate-x-0 group-hover:opacity-100">
              <path fillRule="evenodd" d="M12.97 3.97a.75.75 0 011.06 0l7.5 7.5a.75.75 0 010 1.06l-7.5 7.5a.75.75 0 11-1.06-1.06l6.22-6.22H3a.75.75 0 010-1.5h16.19l-6.22-6.22a.75.75 0 010-1.06z" clipRule="evenodd"></path>
            </svg>                
          </a>
        </div>
      </div>
    </div>
</div>
</div>
<div className="bg-gray-50">
      <div className="mx-auto max-w-7xl py-12 px-4 sm:px-6 lg:flex lg:items-center lg:justify-between lg:py-16 lg:px-8">
        <h2 className="text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl">
          <span className="block">Ready to dive in?</span>
          <span className="block text-orange-600">Start your business today.</span>
        </h2>
        <div className="mt-8 flex lg:mt-0 lg:flex-shrink-0">
          <div className="inline-flex rounded-md shadow">
            <a
              href="#"
              className="inline-flex items-center justify-center rounded-md border border-transparent bg-orange-600 px-5 py-3 text-base font-medium text-white hover:bg-orange-700"
            >
              Services
            </a>
          </div>
          <div className="ml-3 inline-flex rounded-md shadow">
            <a
              href="#"
              className="inline-flex items-center justify-center rounded-md border border-transparent bg-white px-5 py-3 text-base font-medium text-orange-600 hover:bg-orange-50"
            >
              Contact Us
            </a>
          </div>
        </div>
      </div>
     
    </div>
    
  
    </div>
   

  );
}

export default About;
