import React from 'react';


const interior = () => {
return (
	<div id="solution">
   
       
   <section class=" mb-10 md:mb-12 xl:mb-12">

   <section>
                <div class="relative items-center w-full px-5 py-24 mx-auto overflow-hidden md:px-12 lg:px-16 max-w-7xl">
                  <div class="grid items-start grid-cols-1 gap-12 md:grid-cols-2">
                    <div>
                      <div class="max-w-xl lg:p-10">
                        <div><p class="text-2xl font-medium tracking-tight text-black sm:text-4xl">
                        Transform your Space <b>With Style</b>
              </p>
              <p class="max-w-xl mt-4 text-base tracking-tight text-gray-600">
                Use this paragraph to share information about your company or products. Make
                it engaging and interesting, and showcase your brand's personality. Thanks for
                visiting our website!
              </p>
              </div>
                        <div class="flex flex-col items-center justify-center gap-3 mt-10 lg:flex-row lg:justify-start">
                          <a href="#" class="items-center justify-center w-full px-6 py-2.5  text-center text-white duration-200 bg-black border-2 border-black rounded-full inline-flex hover:bg-transparent hover:border-black hover:text-black focus:outline-none lg:w-auto focus-visible:outline-black text-sm focus-visible:ring-black">
                           Button
                          </a>
                          <a href="#" class="inline-flex items-center justify-center text-sm font-semibold text-black duration-200 hover:text-blue-500 focus:outline-none focus-visible:outline-gray-600">
                            Learn more &nbsp; →
                          </a>
                        </div>
                      </div>
                      <div class="flex flex-col gap-3 mt-10 lg:flex-row lg:gap-6">
                        <a class="inline-flex items-center justify-center w-full px-4 py-2 text-sm font-semibold text-white rounded-full active:bg-tangaroa-800 active:text-tangaroa-300 bg-tangaroa-500 focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-tangaroa-900 focus:outline-none group hover:bg-tangaroa-700 hover:text-tangaroa-100 lg:w-auto" href="/overview">Explora all pages
                          <svg class="w-5 h-5 ml-3" fill="none" stroke="currentColor" viewBox="0 0 24 24" stroke-width="1.5" xmlns="http://www.w3.org/2000/svg">
                            <path d="M12.75 15l3-3m0 0l-3-3m3 3h-7.5M21 12a9 9 0 11-18 0 9 9 0 0118 0z" stroke-linecap="round" stroke-linejoin="round"></path></svg></a>
                      </div>
                    </div>
                    <div class="lg:mt-0 lg:ml-auto">
                      <div class="absolute transform lg:-translate-y-1/2 lg:left-1/2 lg:top-1/2 lg:translate-x-8 sm:left-1/2 sm:top-0 sm:translate-x-8">
                        <div class="flex items-center space-x-6 lg:space-x-8">
                          <div class="grid flex-shrink-0 grid-cols-1 gap-y-6 lg:gap-y-8">
                            <div class="h-full overflow-hidden w-44 lg:opacity-100 sm:opacity-0">
                              <img alt="Lexingtøn thumbnail" class="object-cover" src={require("./home-img.png")} decoding="async" height="926" loading="lazy" width="428" />
                            </div>
                            <div class="h-full overflow-hidden w-44">
                              <img alt="Lexingtøn thumbnail" class="object-cover" src={require("./home-img.png")} decoding="async" height="926" loading="lazy" width="428" />
                            </div>
                          </div>
                          <div class="grid flex-shrink-0 grid-cols-1 gap-y-6 lg:gap-y-8">
                            <div class="h-full overflow-hidden w-44">
                              <img alt="Lexingtøn thumbnail" src={require("./home-img.png")} decoding="async" height="926" loading="lazy" width="428" />
                            </div>
                            <div class="h-full overflow-hidden w-44">
                              <img alt="Lexingtøn thumbnail" class="object-cover" src={require("./home-img.png")} decoding="async" height="926" loading="lazy" width="428" />
                            </div>
                            <div class="h-full overflow-hidden w-44">
                              <img alt="Lexingtøn thumbnail" class="object-cover" src={require("./home-img.png")} decoding="async" height="926" loading="lazy" width="428" />
                            </div>
                          </div>
                          <div class="grid flex-shrink-0 grid-cols-1 gap-y-6 lg:gap-y-8">
                            <div class="h-full overflow-hidden w-44">
                              <img alt="Lexingtøn thumbnail" class="object-cover" src={require("./home-img.png")} decoding="async" height="926" loading="lazy" width="428" />
                            </div>
                            <div class="h-full overflow-hidden w-44">
                              <img alt="Lexingtøn thumbnail" class="object-cover" src={require("./home-img.png")} decoding="async" height="926" loading="lazy" width="428" />
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </section>
            


</section>
<section>
<p class="text-3xl md:text-4xl leading-normal md:leading-relaxed mb-2">Complete home interiors in easy steps with <span class="text-transparent bg-clip-text bg-gradient-to-br from-yellow-300 to-red-500">SteelWits</span></p>
       
<div class="bg-gray-100 min-h-screen py-12 flex items-center justify-center">
  <div class="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-4 gap-6">
    <div class="bg-white rounded-lg overflow-hidden shadow-lg transition-transform transform hover:scale-105">
      <div class="p-1 bg-blue-200">
      </div>
      <div class="p-8">
        <h2 class="text-3xl font-bold text-gray-800 mb-4">Discover</h2>
        
        <img src="https://cdn-icons-png.flaticon.com/128/9151/9151156.png?ga=GA1.1.1025882285.1692510312"></img>
        <ul class="text-sm text-gray-600 mb-6">
          <li class="mb-2 flex items-center">
            <svg class="w-4 h-4 mr-2 text-green-500" fill="none" stroke="currentColor" viewBox="0 0 24 24"
              xmlns="http://www.w3.org/2000/svg">
              <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2"
                d="M5 13l4 4L19 7"></path>
            </svg>
            10 Users
          </li>
          <li class="mb-2 flex items-center">
            <svg class="w-4 h-4 mr-2 text-green-500" fill="none" stroke="currentColor" viewBox="0 0 24 24"
              xmlns="http://www.w3.org/2000/svg">
              <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2"
                d="M5 13l4 4L19 7"></path>
            </svg>
            Basic Features
          </li>
          <li class="flex items-center">
            <svg class="w-4 h-4 mr-2 text-green-500" fill="none" stroke="currentColor" viewBox="0 0 24 24"
              xmlns="http://www.w3.org/2000/svg">
              <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2"
                d="M5 13l4 4L19 7"></path>
            </svg>
            24/7 Support
          </li>
        </ul>
      </div>
        </div>
    <div class="bg-white rounded-lg overflow-hidden shadow-lg transition-transform transform hover:scale-105">
      <div class="p-1 bg-red-200">
      </div>
      <div class="p-8">
        <h2 class="text-3xl font-bold text-gray-800 mb-4">Design</h2>
        <img src="https://cdn-icons-png.flaticon.com/128/9151/9151159.png?ga=GA1.1.1025882285.1692510312"></img>
        <ul class="text-sm text-gray-600 mb-6">
          <li class="mb-2 flex items-center">
            <svg class="w-4 h-4 mr-2 text-green-500" fill="none" stroke="currentColor" viewBox="0 0 24 24"
              xmlns="http://www.w3.org/2000/svg">
              <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2"
                d="M5 13l4 4L19 7"></path>
            </svg>
            25 Users
          </li>
          <li class="mb-2 flex items-center">
            <svg class="w-4 h-4 mr-2 text-green-500" fill="none" stroke="currentColor" viewBox="0 0 24 24"
              xmlns="http://www.w3.org/2000/svg">
              <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2"
                d="M5 13l4 4L19 7"></path>
            </svg>
            Advanced Features
          </li>
          <li class="flex items-center">
            <svg class="w-4 h-4 mr-2 text-green-500" fill="none" stroke="currentColor" viewBox="0 0 24 24"
              xmlns="http://www.w3.org/2000/svg">
              <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2"
                d="M5 13l4 4L19 7"></path>
            </svg>
            24/7 Support
          </li>
        </ul>
      </div>
      
    </div>
    
    <div class="bg-white rounded-lg overflow-hidden shadow-lg transition-transform transform hover:scale-105">
      <div class="p-1 bg-yellow-200">
      </div>
      <div class="p-8">
        <h2 class="text-3xl font-bold text-gray-800 mb-4">Implement</h2>
        <img src="https://cdn-icons-png.flaticon.com/128/9151/9151124.png?ga=GA1.1.1025882285.1692510312"></img>
        <ul class="text-sm text-gray-600 mb-6">
          <li class="mb-2 flex items-center">
            <svg class="w-4 h-4 mr-2 text-green-500" fill="none" stroke="currentColor" viewBox="0 0 24 24"
              xmlns="http://www.w3.org/2000/svg">
              <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2"
                d="M5 13l4 4L19 7"></path>
            </svg>
            25 Users
          </li>
          <li class="mb-2 flex items-center">
            <svg class="w-4 h-4 mr-2 text-green-500" fill="none" stroke="currentColor" viewBox="0 0 24 24"
              xmlns="http://www.w3.org/2000/svg">
              <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2"
                d="M5 13l4 4L19 7"></path>
            </svg>
            Advanced Features
          </li>
          <li class="flex items-center">
            <svg class="w-4 h-4 mr-2 text-green-500" fill="none" stroke="currentColor" viewBox="0 0 24 24"
              xmlns="http://www.w3.org/2000/svg">
              <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2"
                d="M5 13l4 4L19 7"></path>
            </svg>
            24/7 Support
          </li>
        </ul>
      </div>
      
    </div>
    <div class="bg-white rounded-lg overflow-hidden shadow-lg transition-transform transform hover:scale-105">
      <div class="p-1 bg-purple-200">
      </div>
      <div class="p-8">
        <h2 class="text-3xl font-bold text-gray-800 mb-4">Move-In</h2>
        <img src="https://cdn-icons-png.flaticon.com/128/9151/9151121.png?ga=GA1.1.1025882285.1692510312"></img>
        
        <ul class="text-sm text-gray-600 mb-6">
          <li class="mb-2 flex items-center">
            <svg class="w-4 h-4 mr-2 text-green-500" fill="none" stroke="currentColor" viewBox="0 0 24 24"
              xmlns="http://www.w3.org/2000/svg">
              <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2"
                d="M5 13l4 4L19 7"></path>
            </svg>
            Unlimited Users
          </li>
          <li class="mb-2 flex items-center">
            <svg class="w-4 h-4 mr-2 text-green-500" fill="none" stroke="currentColor" viewBox="0 0 24 24"
              xmlns="http

://www.w3.org/2000/svg">
              <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2"
                d="M5 13l4 4L19 7"></path>
            </svg>
            Premium Features
          </li>
          <li class="flex items-center">
            <svg class="w-4 h-4 mr-2 text-green-500" fill="none" stroke="currentColor" viewBox="0 0 24 24"
              xmlns="http://www.w3.org/2000/svg">
              <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2"
                d="M5 13l4 4L19 7"></path>
            </svg>
            24/7 Priority Support
          </li>
        </ul>
      </div>
     
    </div>

  </div>
</div>
Why Steelwits?






Explore more than just modular design ideas with our experts.
Complete the designs with painting, flooring and other decor solutions.
Move in with ease, with our hassle-free civil work and installation services.

Loved us? Now refer us!
Refer and earn ₹ 10,000 every time your friends try SteelWits.

Our trusted partners


End-to-end offerings

Modular Kitchen Designs
Modular Living Room
Rennovative Designs

Wardrobe Designs
</section>
<section class="bg-white  py-12 px-4 lg:px-16"> 
    <div class="container mx-auto px-[12px] md:px-24 xl:px-12 max-w-[1300px] nanum2">
        <h1 class="text-center text-5xl pb-12">Industries we serve</h1>
        <div class="grid grid-cols-1 lg:grid-cols-2 xl:grid-cols-4 gap-x-4 gap-y-28 lg:gap-y-16">
        <div class="relative group h-48 flex   flex-col rounded-xl bg-white bg-clip-border text-gray-700 shadow-md">
                <a href="#" class="block">
                    <div class="h-28">
                        <div
                            class="absolute -top-20 lg:top-[-10%] left-[5%] z-40  group-hover:top-[-40%] group-hover:opacity-[0.9]   duration-300 w-[90%] h-48 bg-steel-yellow rounded-xl justify-items-center align-middle">
                         <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" className="w-6 h-6">
  <path d="M19.006 3.705a.75.75 0 00-.512-1.41L6 6.838V3a.75.75 0 00-.75-.75h-1.5A.75.75 0 003 3v4.93l-1.006.365a.75.75 0 00.512 1.41l16.5-6z" />
  <path fillRule="evenodd" d="M3.019 11.115L18 5.667V9.09l4.006 1.456a.75.75 0 11-.512 1.41l-.494-.18v8.475h.75a.75.75 0 010 1.5H2.25a.75.75 0 010-1.5H3v-9.129l.019-.006zM18 20.25v-9.565l1.5.545v9.02H18zm-9-6a.75.75 0 00-.75.75v4.5c0 .414.336.75.75.75h3a.75.75 0 00.75-.75V15a.75.75 0 00-.75-.75H9z" clipRule="evenodd" />
</svg>


                            <img src="https://icons8.com/icon/QHJ0cVJXSlCx/shop"
                                class="w-36 h-36  mt-6 m-auto" alt="Automotive" title="Automotive" loading="lazy"
                                width="200" height="200" />
                        </div>
                    </div>
                    <div class="p-6   z-10 w-full   ">
                        <p
                            class="mb-2 inline-block text-tg text-center w-full  text-xl  font-sans  font-semibold leading-snug tracking-normal   antialiased">
                            Home Interior
                        </p>
                    </div>
                </a>
            </div>
        <div class="relative group h-48 flex   flex-col rounded-xl bg-white bg-clip-border text-gray-700 shadow-md">
                <a href="#" class="block">
                    <div class="h-28">
                        <div
                            class="absolute -top-20 lg:top-[-10%] left-[5%] z-40  group-hover:top-[-40%] group-hover:opacity-[0.9]   duration-300 w-[90%] h-48 bg-steel-yellow rounded-xl justify-items-center align-middle">
                        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" className="w-6 h-6">
  <path fillRule="evenodd" d="M3 2.25a.75.75 0 000 1.5v16.5h-.75a.75.75 0 000 1.5H15v-18a.75.75 0 000-1.5H3zM6.75 19.5v-2.25a.75.75 0 01.75-.75h3a.75.75 0 01.75.75v2.25a.75.75 0 01-.75.75h-3a.75.75 0 01-.75-.75zM6 6.75A.75.75 0 016.75 6h.75a.75.75 0 010 1.5h-.75A.75.75 0 016 6.75zM6.75 9a.75.75 0 000 1.5h.75a.75.75 0 000-1.5h-.75zM6 12.75a.75.75 0 01.75-.75h.75a.75.75 0 010 1.5h-.75a.75.75 0 01-.75-.75zM10.5 6a.75.75 0 000 1.5h.75a.75.75 0 000-1.5h-.75zm-.75 3.75A.75.75 0 0110.5 9h.75a.75.75 0 010 1.5h-.75a.75.75 0 01-.75-.75zM10.5 12a.75.75 0 000 1.5h.75a.75.75 0 000-1.5h-.75zM16.5 6.75v15h5.25a.75.75 0 000-1.5H21v-12a.75.75 0 000-1.5h-4.5zm1.5 4.5a.75.75 0 01.75-.75h.008a.75.75 0 01.75.75v.008a.75.75 0 01-.75.75h-.008a.75.75 0 01-.75-.75v-.008zm.75 2.25a.75.75 0 00-.75.75v.008c0 .414.336.75.75.75h.008a.75.75 0 00.75-.75v-.008a.75.75 0 00-.75-.75h-.008zM18 17.25a.75.75 0 01.75-.75h.008a.75.75 0 01.75.75v.008a.75.75 0 01-.75.75h-.008a.75.75 0 01-.75-.75v-.008z" clipRule="evenodd" />
</svg>


                        </div>
                    </div>
                    <div class="p-6   z-10 w-full   ">
                        <p
                            class="mb-2 inline-block text-tg text-center w-full  text-xl  font-sans  font-semibold leading-snug tracking-normal   antialiased">
                            Commercial
                        </p>
                    </div>
                </a>
            </div>
            <div class="relative group h-48 flex   flex-col rounded-xl bg-white bg-clip-border text-gray-700 shadow-md">
                <a href="#" class="block">
                    <div class="h-28">
                        <div
                            class="absolute -top-20 lg:top-[-10%] left-[5%] z-40  group-hover:top-[-40%] group-hover:opacity-[0.9]   duration-300 w-[90%] h-48 bg-steel-yellow rounded-xl justify-items-center align-middle">
                            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" className="w-6 h-6">
  <path d="M3.375 4.5C2.339 4.5 1.5 5.34 1.5 6.375V13.5h12V6.375c0-1.036-.84-1.875-1.875-1.875h-8.25zM13.5 15h-12v2.625c0 1.035.84 1.875 1.875 1.875h.375a3 3 0 116 0h3a.75.75 0 00.75-.75V15z" />
  <path d="M8.25 19.5a1.5 1.5 0 10-3 0 1.5 1.5 0 003 0zM15.75 6.75a.75.75 0 00-.75.75v11.25c0 .087.015.17.042.248a3 3 0 015.958.464c.853-.175 1.522-.935 1.464-1.883a18.659 18.659 0 00-3.732-10.104 1.837 1.837 0 00-1.47-.725H15.75z" />
  <path d="M19.5 19.5a1.5 1.5 0 10-3 0 1.5 1.5 0 003 0z" />
</svg>

                            <img src="https://epicpadprinting.com/public/img/indus/Automotive.png"
                                class="w-36 h-36  mt-6 m-auto" alt="Automotive" title="Automotive" loading="lazy"
                                width="200" height="200" />
                        </div>
                    </div>
                    <div class="p-6   z-10 w-full   ">
                        <p
                            class="mb-2 inline-block text-tg text-center w-full  text-xl  font-sans  font-semibold leading-snug tracking-normal   antialiased">
                            Automotive
                        </p>
                    </div>
                </a>
            </div>

    

            <div class="relative group h-48 flex   flex-col rounded-xl bg-white bg-clip-border text-gray-700 shadow-md">
                <a href="#" class="block">
                    <div class="h-28">
                        <div
                            class="absolute -top-20 lg:top-[-10%] left-[5%] z-40  group-hover:top-[-40%] group-hover:opacity-[0.9]   duration-300 w-[90%] h-48 bg-steel-yellow rounded-xl justify-items-center align-middle">
                            <img src="https://epicpadprinting.com/public/img/indus/MEDICAL.png"
                                class="w-36 h-36  mt-6 m-auto" alt="Medical" title="Medical" loading="lazy" width="200"
                                height="200" />
                        </div>
                    </div>
                    <div class="p-6   z-10 w-full   ">
                        <p
                            class="mb-2 inline-block text-tg text-center w-full  text-xl  font-sans  font-semibold leading-snug tracking-normal   antialiased">
                            Hospital & Pharmacy
                        </p>
                    </div>
                </a>
            </div>

            

            <div class="relative group h-48 flex   flex-col rounded-xl bg-white bg-clip-border text-gray-700 shadow-md">
                <a href="#"
                    class="block">
                    <div class="h-28">
                        <div
                            class="absolute -top-20 lg:top-[-10%] left-[5%] z-40  group-hover:top-[-40%] group-hover:opacity-[0.9]   duration-300 w-[90%] h-48 bg-steel-yellow rounded-xl justify-items-center align-middle">
                            <img src="https://epicpadprinting.com/public/img/indus/Jewelry-coins-and-medallions.png"
                                class="w-36 h-36  mt-6 m-auto" alt="Jewelry Coins and Medallions"
                                title="Jewelry Coins and Medallions" loading="lazy" width="200" height="200" />
                        </div>
                    </div>
                    <div class="p-6   z-10 w-full   ">
                        <p
                            class="mb-2 inline-block text-tg text-center w-full  text-xl  font-sans  font-semibold leading-snug tracking-normal   antialiased">
                            Jewelry Shops
                        </p>
                    </div>
                </a>
            </div>

            <div class="relative group h-48 flex   flex-col rounded-xl bg-white bg-clip-border text-gray-700 shadow-md">
                <a href="#"
                    class="block">
                    <div class="h-28">
                        <div
                            class="absolute -top-20 lg:top-[-10%] left-[5%] z-40  group-hover:top-[-40%] group-hover:opacity-[0.9]   duration-300 w-[90%] h-48 bg-steel-yellow rounded-xl justify-items-center align-middle">
                            <img src="https://epicpadprinting.com/public/img/indus/Packaging-FMCG.png"
                                class="w-36 h-36  mt-6 m-auto" alt="Packaging / FMCG" title="Packaging / FMCG"
                                loading="lazy" width="200" height="200" />
                        </div>
                    </div>
                    <div class="p-6   z-10 w-full   ">
                        <p
                            class="mb-2 inline-block text-tg text-center w-full  text-xl  font-sans  font-semibold leading-snug tracking-normal   antialiased">
                            Super Markets
                        </p>
                    </div>
                </a>
            </div>

           
            <div class="relative group h-48 flex   flex-col rounded-xl bg-white bg-clip-border text-gray-700 shadow-md">
                <a href="#"
                    class="block">
                    <div class="h-28">
                        <div
                            class="absolute -top-20 lg:top-[-10%] left-[5%] z-40  group-hover:top-[-40%] group-hover:opacity-[0.9]   duration-300 w-[90%] h-48 bg-steel-yellow rounded-xl justify-items-center align-middle">
                           <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" className="w-6 h-6">
  <path d="M11.7 2.805a.75.75 0 01.6 0A60.65 60.65 0 0122.83 8.72a.75.75 0 01-.231 1.337 49.949 49.949 0 00-9.902 3.912l-.003.002-.34.18a.75.75 0 01-.707 0A50.009 50.009 0 007.5 12.174v-.224c0-.131.067-.248.172-.311a54.614 54.614 0 014.653-2.52.75.75 0 00-.65-1.352 56.129 56.129 0 00-4.78 2.589 1.858 1.858 0 00-.859 1.228 49.803 49.803 0 00-4.634-1.527.75.75 0 01-.231-1.337A60.653 60.653 0 0111.7 2.805z" />
  <path d="M13.06 15.473a48.45 48.45 0 017.666-3.282c.134 1.414.22 2.843.255 4.285a.75.75 0 01-.46.71 47.878 47.878 0 00-8.105 4.342.75.75 0 01-.832 0 47.877 47.877 0 00-8.104-4.342.75.75 0 01-.461-.71c.035-1.442.121-2.87.255-4.286A48.4 48.4 0 016 13.18v1.27a1.5 1.5 0 00-.14 2.508c-.09.38-.222.753-.397 1.11.452.213.901.434 1.346.661a6.729 6.729 0 00.551-1.608 1.5 1.5 0 00.14-2.67v-.645a48.549 48.549 0 013.44 1.668 2.25 2.25 0 002.12 0z" />
  <path d="M4.462 19.462c.42-.419.753-.89 1-1.394.453.213.902.434 1.347.661a6.743 6.743 0 01-1.286 1.794.75.75 0 11-1.06-1.06z" />
</svg>

                            <img src="https://epicpadprinting.com/public/img/indus/Backpacks-and-umbrella.png"
                                class="w-36 h-36  mt-6 m-auto" alt="Backpacks and Umbrella"
                                title="Backpacks and Umbrella" loading="lazy" width="200" height="200" />
                        </div>
                    </div>
                    <div class="p-6   z-10 w-full   ">
                        <p
                            class="mb-2 inline-block text-tg text-center w-full  text-xl  font-sans  font-semibold leading-snug tracking-normal   antialiased">
                            Education
                        </p>
                    </div>
                </a>
            </div>

          

            <div class="relative group h-48 flex   flex-col rounded-xl bg-white bg-clip-border text-gray-700 shadow-md">
                <a href="#" class="block">
                    <div class="h-28">
                        <div
                            class="absolute -top-20 lg:top-[-10%] left-[5%] z-40  group-hover:top-[-40%] group-hover:opacity-[0.9]   duration-300 w-[90%] h-48 bg-steel-yellow rounded-xl justify-items-center align-middle">
                           <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-6 h-6">
  <path strokeLinecap="round" strokeLinejoin="round" d="M13.5 21v-7.5a.75.75 0 01.75-.75h3a.75.75 0 01.75.75V21m-4.5 0H2.36m11.14 0H18m0 0h3.64m-1.39 0V9.349m-16.5 11.65V9.35m0 0a3.001 3.001 0 003.75-.615A2.993 2.993 0 009.75 9.75c.896 0 1.7-.393 2.25-1.016a2.993 2.993 0 002.25 1.016c.896 0 1.7-.393 2.25-1.016a3.001 3.001 0 003.75.614m-16.5 0a3.004 3.004 0 01-.621-4.72L4.318 3.44A1.5 1.5 0 015.378 3h13.243a1.5 1.5 0 011.06.44l1.19 1.189a3 3 0 01-.621 4.72m-13.5 8.65h3.75a.75.75 0 00.75-.75V13.5a.75.75 0 00-.75-.75H6.75a.75.75 0 00-.75.75v3.75c0 .415.336.75.75.75z" />
</svg>

                            <img src="https://epicpadprinting.com/public/img/indus/Cosmetics.png"
                                class="w-36 h-36  mt-6 m-auto" alt="Cosmetics" title="Cosmetics" loading="lazy"
                                width="200" height="200" />
                        </div>
                    </div>
                    <div class="p-6   z-10 w-full   ">
                        <p
                            class="mb-2 inline-block text-tg text-center w-full  text-xl  font-sans  font-semibold leading-snug tracking-normal   antialiased">
                            Cosmetic Shops
                        </p>
                    </div>
                </a>
            </div>


            <div class="relative group h-48 flex   flex-col rounded-xl bg-white bg-clip-border text-gray-700 shadow-md">
                <a href="#" class="block">
                    <div class="h-28">
                        <div
                            class="absolute -top-20 lg:top-[-10%] left-[5%] z-40  group-hover:top-[-40%] group-hover:opacity-[0.9]   duration-300 w-[90%] h-48 bg-steel-yellow rounded-xl justify-items-center align-middle">
                           <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-6 h-6">
  <path strokeLinecap="round" strokeLinejoin="round" d="M10.34 15.84c-.688-.06-1.386-.09-2.09-.09H7.5a4.5 4.5 0 110-9h.75c.704 0 1.402-.03 2.09-.09m0 9.18c.253.962.584 1.892.985 2.783.247.55.06 1.21-.463 1.511l-.657.38c-.551.318-1.26.117-1.527-.461a20.845 20.845 0 01-1.44-4.282m3.102.069a18.03 18.03 0 01-.59-4.59c0-1.586.205-3.124.59-4.59m0 9.18a23.848 23.848 0 018.835 2.535M10.34 6.66a23.847 23.847 0 008.835-2.535m0 0A23.74 23.74 0 0018.795 3m.38 1.125a23.91 23.91 0 011.014 5.395m-1.014 8.855c-.118.38-.245.754-.38 1.125m.38-1.125a23.91 23.91 0 001.014-5.395m0-3.46c.495.413.811 1.035.811 1.73 0 .695-.316 1.317-.811 1.73m0-3.46a24.347 24.347 0 010 3.46" />
</svg>

                            <img src="https://epicpadprinting.com/public/img/indus/Promotional.png"
                                class="w-36 h-36  mt-6 m-auto" alt="Promotional" title="Promotional" loading="lazy"
                                width="200" height="200" />
                        </div>
                    </div>
                    <div class="p-6   z-10 w-full   ">
                        <p
                            class="mb-2 inline-block text-tg text-center w-full  text-xl  font-sans  font-semibold leading-snug tracking-normal   antialiased">
                            Promotionals 
                        </p>
                    </div>
                </a>
            </div>

            <div class="relative group h-48 flex   flex-col rounded-xl bg-white bg-clip-border text-gray-700 shadow-md">
                <a href="#"
                    class="block">
                    <div class="h-28">
                        <div
                            class="absolute -top-20 lg:top-[-10%] left-[5%] z-40  group-hover:top-[-40%] group-hover:opacity-[0.9]   duration-300 w-[90%] h-48 bg-steel-yellow rounded-xl justify-items-center align-middle">
                           <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-6 h-6">
  <path strokeLinecap="round" strokeLinejoin="round" d="M16.5 18.75h-9m9 0a3 3 0 013 3h-15a3 3 0 013-3m9 0v-3.375c0-.621-.503-1.125-1.125-1.125h-.871M7.5 18.75v-3.375c0-.621.504-1.125 1.125-1.125h.872m5.007 0H9.497m5.007 0a7.454 7.454 0 01-.982-3.172M9.497 14.25a7.454 7.454 0 00.981-3.172M5.25 4.236c-.982.143-1.954.317-2.916.52A6.003 6.003 0 007.73 9.728M5.25 4.236V4.5c0 2.108.966 3.99 2.48 5.228M5.25 4.236V2.721C7.456 2.41 9.71 2.25 12 2.25c2.291 0 4.545.16 6.75.47v1.516M7.73 9.728a6.726 6.726 0 002.748 1.35m8.272-6.842V4.5c0 2.108-.966 3.99-2.48 5.228m2.48-5.492a46.32 46.32 0 012.916.52 6.003 6.003 0 01-5.395 4.972m0 0a6.726 6.726 0 01-2.749 1.35m0 0a6.772 6.772 0 01-3.044 0" />
</svg>

                            <img src="https://epicpadprinting.com/public/img/indus/Sports.png"
                                class="w-36 h-36  mt-6 m-auto" alt="Sports" title="Sports" loading="lazy" width="200"
                                height="200" />
                        </div>
                    </div>
                    <div class="p-6   z-10 w-full   ">
                        <p
                            class="mb-2 inline-block text-tg text-center w-full  text-xl  font-sans  font-semibold leading-snug tracking-normal   antialiased">
                            Sports Complex
                        </p>
                    </div>
                </a>
            </div>

       

            <div class="relative group h-48 flex   flex-col rounded-xl bg-white bg-clip-border text-gray-700 shadow-md">
                <a href=" "
                    class="block">
                    <div class="h-28">
                        <div
                            class="absolute -top-20 lg:top-[-10%] left-[5%] z-40  group-hover:top-[-40%] group-hover:opacity-[0.9]   duration-300 w-[90%] h-48 bg-steel-yellow rounded-xl justify-items-center align-middle">
                            <img src="https://epicpadprinting.com/public/img/indus/Utensils.png"
                                class="w-36 h-36  mt-6 m-auto" alt="Utensils" title="Utensils" loading="lazy"
                                width="200" height="200" />
                        </div>
                    </div>
                    <div class="p-6   z-10 w-full   ">
                        <p
                            class="mb-2 inline-block text-tg text-center w-full  text-xl  font-sans  font-semibold leading-snug tracking-normal   antialiased">
                            Hotels
                        </p>
                    </div>
                </a>
            </div>

        </div>
    </div>
</section>
<section>
    <div class="bg-white text-black py-8">
    <div class="container mx-auto flex flex-col items-start md:flex-row my-12 md:my-2">
      <div class="flex flex-col w-full sticky md:top-36 lg:w-1/3 mt-2 md:mt-12 px-8">
        <p class="ml-2 text-yellow-300 uppercase tracking-loose">Working Process</p>
        <p class="text-3xl md:text-4xl leading-normal md:leading-relaxed mb-2">Working Process of <span class="text-transparent bg-clip-text bg-gradient-to-br from-yellow-300 to-red-500">SteelWits</span></p>
        <p class="text-sm md:text-base text-black-50 mb-4">
          Here’s your guide to the tech fest 2021 process. Go through all the steps to know the exact process of the
          fest.
        </p>
     
      </div>
      <div class="ml-0 md:ml-12 lg:w-2/3 sticky">
        <div class="container mx-auto w-full h-full">
          <div class="relative wrap overflow-hidden p-10 h-full">
            <div class="border-2-2 border-yellow-555 absolute h-full border"
            ></div>
            <div class="border-2-2 border-yellow-555 absolute h-full border"
             ></div>
            <div class="mb-8 flex justify-between flex-row-reverse items-center w-full left-timeline">
              <div class="order-1 w-5/12"></div>
              <div class="order-1 w-5/12 px-1 py-4 text-right">
                <p class="mb-3 text-base text-yellow-300">Involve</p>
                <h4 class="mb-3 font-bold text-lg md:text-2xl">Registration</h4>
                <p class="text-sm md:text-base leading-snug text-black-50 text-opacity-100">
                  Pick your favourite and register in that Design by filling the form corresponding to that
                  design. Its that easy :)
                </p>
              </div>
            </div>
            <div class="mb-8 flex justify-between items-center w-full right-timeline">
              <div class="order-1 w-5/12"></div>
              <div class="order-1  w-5/12 px-1 py-4 text-left">
                <p class="mb-3 text-base text-yellow-300">6-9 May, 2021</p>
                <h4 class="mb-3 font-bold text-lg md:text-2xl">Participation</h4>
                <p class="text-sm md:text-base leading-snug text-black-50 text-opacity-100">
                  Participate online. The links for your registered events will be sent to you via email and whatsapp
                  groups. Use those links and show your talent.
                </p>
              </div>
            </div>
            <div class="mb-8 flex justify-between flex-row-reverse items-center w-full left-timeline">
              <div class="order-1 w-5/12"></div>
              <div class="order-1 w-5/12 px-1 py-4 text-right">
                <p class="mb-3 text-base text-yellow-300"> 10 May, 2021</p>
                <h4 class="mb-3 font-bold text-lg md:text-2xl">Result Declaration</h4>
                <p class="text-sm md:text-base leading-snug text-black-50 text-opacity-100">
                  The ultimate genius will be revealed by our judging panel on 10th May, 2021 and the resukts will be
                  announced on the whatsapp groups and will be mailed to you.
                </p>
              </div>
            </div>

            <div class="mb-8 flex justify-between items-center w-full right-timeline">
              <div class="order-1 w-5/12"></div>

              <div class="order-1  w-5/12 px-1 py-4">
                <p class="mb-3 text-base text-yellow-300">12 May, 2021</p>
                <h4 class="mb-3 font-bold  text-lg md:text-2xl text-left">Prize Distribution</h4>
                <p class="text-sm md:text-base leading-snug text-black-50 text-opacity-100">
                  The winners will be contacted by our team for their addresses and the winning goodies will be sent at
                  their addresses.
                </p>
              </div>
            </div>
          </div>
          <img class="mx-auto -mt-36 md:-mt-36" src="https://user-images.githubusercontent.com/54521023/116968861-ef21a000-acd2-11eb-95ac-a34b5b490265.png" />
        </div>
      </div>
    </div>
  </div>
  </section>         
<section class="bg-white py-10 md:py-16 xl:relative">

<div class="container max-w-screen-xl mx-auto px-4">

<div class="flex flex-col xl:flex-row justify-end">

        <div class="hidden xl:block xl:absolute left-0 bottom-0 w-full">
            <img src={require("./feature-img.png")} alt="Home img" />
        </div>

        <div class="">

            <h1 class="font-semibold text-gray-900 text-xl md:text-4xl text-center leading-normal mb-6">Choice of various types of <br /> house</h1>

            <p class="font-normal text-gray-400 text-md md:text-xl text-center mb-16">We provide a wide of selection of home types for you and your <br /> family and are free to choose a home model</p>

            <div class="flex flex-col md:flex-row justify-center xl:justify-start space-x-4 mb-20">
                <div class="px-8 h-20 mx-auto md:mx-0 bg-gray-200 rounded-lg flex items-center justify-center mb-5 md:mb-0">
                    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-check-circle  text-green-900"><path d="M22 11.08V12a10 10 0 1 1-5.93-9.14"></path><polyline points="22 4 12 14.01 9 11.01"></polyline></svg>
                </div>
                
                <div class="text-center md:text-left">
                    <h4 class="font-semibold text-gray-900 text-2xl mb-2">Best Home Guarantee</h4>
                    <p class="font-normal text-gray-400 text-xl leading-relaxed">We guarantees the quality of your home you bought <br /> from SteelWits</p>
                </div>
            </div>

            <div class="flex flex-col md:flex-row justify-center xl:justify-start space-x-4 mb-20">
                <div class="px-8 h-20 mx-auto md:mx-0 bg-gray-200 rounded-lg flex items-center justify-center mb-5 md:mb-0">
                    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-lock  text-green-900"><rect x="3" y="11" width="18" height="11" rx="2" ry="2"></rect><path d="M7 11V7a5 5 0 0 1 10 0v4"></path></svg>
                </div>

                <div class="text-center md:text-left">
                    <h4 class="font-semibold text-gray-900 text-2xl mb-2">Safe Transaction</h4>
                    <p class="font-normal text-gray-400 text-xl leading-relaxed">Your transactions will always be kept confidential <br /> and will get discounted</p>
                </div>
            </div>

            <div class="flex flex-col md:flex-row justify-center xl:justify-start space-x-4">
                <div class="px-8 h-20 mx-auto md:mx-0 bg-gray-200 rounded-lg flex items-center justify-center mb-5 md:mb-0">
                    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-credit-card  text-green-900"><rect x="1" y="4" width="22" height="16" rx="2" ry="2"></rect><line x1="1" y1="10" x2="23" y2="10"></line></svg>
                </div>

                <div class="text-center md:text-left">
                    <h4 class="font-semibold text-gray-900 text-2xl mb-2">Low and Cost Home Taxes</h4>
                    <p class="font-normal text-gray-400 text-xl leading-relaxed">By buying a house from SteelWits, you will get a tax <br /> discount</p>
                </div>
            </div>

        </div>

</div>

</div> 

</section>

<section>
                <div class="relative items-center w-full px-5 pt-24 mx-auto md:px-12 lg:px-16 max-w-7xl lg:py-24">
                  <div class="gap-12 lg:grid-cols-2 lg:grid">
                    <div>
                      <div class="max-w-xl">
                        <div>
                          <p class="text-2xl font-medium tracking-tight text-black sm:text-4xl">
                            I am a slightly longer heading than the others, to explain your
                            projects benefits and make conversions
                          </p>
                        </div>
                      </div>
                      <div class="mx-auto mt-12 lg:max-w-7xl">
                        <ul role="list" class="grid grid-cols-2 gap-4 list-none lg:gap-6">
                          <li>
                            <div>
                              <p class="mt-5 text-lg font-medium leading-6 text-black">
                                Easy onboarding
                              </p>
                            </div>
                            <div class="mt-2 text-base text-black-500">
                              Plus, our platform is constantly evolving to meet the changing
                              needs.
                            </div>
                          </li>
                          <li>
                            <div>
                              <p class="mt-5 text-lg font-medium leading-6 text-black">
                                Customer support
                              </p>
                            </div>
                            <div class="mt-2 text-base text-black-500">
                              Plus, our platform is constantly evolving to meet the changing
                              needs.
                            </div>
                          </li>
                        </ul>
                      </div>
                    </div>
                    <div class="">
                      <div class="max-w-xl py-12 mx-auto text-left lg:max-w-7xl">
                        <h2 class="sr-only">Features.</h2>
                        <div>
                          <div class="grid grid-cols-3 gap-12 text-center lg:grid-cols-3 lg:space-y-0">
                            <div>
                              <div>
                                <div class="flex items-center justify-center w-12 h-12 mx-auto text-black bg-gray-100 rounded-xl">
                                  ❖
                                </div>
                                <p class="mt-5 font-medium leading-6 text-black">Footers</p>
                              </div>
                            </div>
                            <div>
                              <div>
                                <div class="flex items-center justify-center w-12 h-12 mx-auto text-black bg-gray-100 rounded-xl">
                                  ❖
                                </div>
                                <p class="mt-5 font-medium leading-6 text-black">CTA's</p>
                              </div>
                            </div>
                            <div>
                              <div>
                                <div class="flex items-center justify-center w-12 h-12 mx-auto text-black bg-gray-100 rounded-xl">
                                  ❖
                                </div>
                                <p class="mt-5 font-medium leading-6 text-black">Forms</p>
                              </div>
                            </div>
                            <div>
                              <div>
                                <div class="flex items-center justify-center w-12 h-12 mx-auto text-black bg-gray-100 rounded-xl">
                                  ❖
                                </div>
                                <p class="mt-5 font-medium leading-6 text-black">
                                  Testimonials
                                </p>
                              </div>
                            </div>
                            <div>
                              <div>
                                <div class="flex items-center justify-center w-12 h-12 mx-auto text-black bg-gray-100 rounded-xl">
                                  ❖
                                </div>
                                <p class="mt-5 font-medium leading-6 text-black">Pricing</p>
                              </div>
                            </div>
                            <div>
                              <div>
                                <div class="flex items-center justify-center w-12 h-12 mx-auto text-black bg-gray-100 rounded-xl">
                                  ❖
                                </div>
                                <p class="mt-5 font-medium leading-6 text-black">Blogs</p>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </section>
<section class="bg-white py-10 md:py-16">

<div class="container max-w-screen-xl mx-auto px-4 xl:relative">

    <p class="font-normal text-gray-400 text-lg md:text-xl text-center uppercase mb-6">Testimonial</p>

    <h1 class="font-semibold text-gray-900 text-2xl md:text-4xl text-center leading-normal mb-14">What People Say <br /> About <span class="text-transparent bg-clip-text bg-gradient-to-br from-yellow-300 to-red-500">SteelWits</span> Interior Services </h1>
    <div class="flex flex-col md:flex-row md:items-center justify-center md:space-x-8 lg:space-x-12 mb-10 md:mb-20">
        <div class="bg-gray-100 rounded-lg mb-10 md:mb-0">
            <img src="assets/image/testimoni-3.png" alt="Image" class="mx-8 my-8" />
            <div class="flex items-center gap-5 mx-8">
                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-star text-yellow-500"><polygon points="12 2 15.09 8.26 22 9.27 17 14.14 18.18 21.02 12 17.77 5.82 21.02 7 14.14 2 9.27 8.91 8.26 12 2"></polygon></svg>
                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-star text-yellow-500"><polygon points="12 2 15.09 8.26 22 9.27 17 14.14 18.18 21.02 12 17.77 5.82 21.02 7 14.14 2 9.27 8.91 8.26 12 2"></polygon></svg>
                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-star text-yellow-500"><polygon points="12 2 15.09 8.26 22 9.27 17 14.14 18.18 21.02 12 17.77 5.82 21.02 7 14.14 2 9.27 8.91 8.26 12 2"></polygon></svg>
                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-star text-yellow-500"><polygon points="12 2 15.09 8.26 22 9.27 17 14.14 18.18 21.02 12 17.77 5.82 21.02 7 14.14 2 9.27 8.91 8.26 12 2"></polygon></svg>
                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-star text-yellow-500"><polygon points="12 2 15.09 8.26 22 9.27 17 14.14 18.18 21.02 12 17.77 5.82 21.02 7 14.14 2 9.27 8.91 8.26 12 2"></polygon></svg>
            </div>
            <p class="font-normal text-sm lg:text-md text-gray-400 mx-8 my-8">I recommend anyone to buy house on <br /> SteelWits. I received great customer service <br /> from the specialists who helped me.</p>
            <h3 class="font-semibold text-gray-900 text-xl md:text-2xl lg:text-3xl mx-8 mb-8"> Govardhan Reddy</h3>
        </div>
        <div class="bg-gray-100 rounded-lg">
            <img src="assets/image/testimoni-4.png" alt="Image" class="mx-8 my-8" />
            <div class="flex items-center gap-5 mx-8">
                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-star text-yellow-500"><polygon points="12 2 15.09 8.26 22 9.27 17 14.14 18.18 21.02 12 17.77 5.82 21.02 7 14.14 2 9.27 8.91 8.26 12 2"></polygon></svg>
                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-star text-yellow-500"><polygon points="12 2 15.09 8.26 22 9.27 17 14.14 18.18 21.02 12 17.77 5.82 21.02 7 14.14 2 9.27 8.91 8.26 12 2"></polygon></svg>
                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-star text-yellow-500"><polygon points="12 2 15.09 8.26 22 9.27 17 14.14 18.18 21.02 12 17.77 5.82 21.02 7 14.14 2 9.27 8.91 8.26 12 2"></polygon></svg>
                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-star text-yellow-500"><polygon points="12 2 15.09 8.26 22 9.27 17 14.14 18.18 21.02 12 17.77 5.82 21.02 7 14.14 2 9.27 8.91 8.26 12 2"></polygon></svg>
                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-star text-yellow-500"><polygon points="12 2 15.09 8.26 22 9.27 17 14.14 18.18 21.02 12 17.77 5.82 21.02 7 14.14 2 9.27 8.91 8.26 12 2"></polygon></svg>
            </div>

            <p class="font-normal text-sm lg:text-md text-gray-400 mx-8 my-8">SteelWits is the best property agent in the <br /> world. I received great customer service <br /> from the SteelWits agent</p>
            <h3 class="font-semibold text-gray-900 text-xl md:text-2xl lg:text-3xl mx-8 mb-8">Vikas Interiors</h3>
        </div>

    </div>

</div> 

</section>           
</div>

);
};

export default interior;
